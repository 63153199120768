import React from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
  
export const Navbar = (props) => {
  return (
    <div>



    <header className="shadow-sm">
        <nav className="navbar-desktop d-none d-lg-block w-100">
            <div className="container d-flex justify-content-between align-items-center position-relative">
                <Link className="navbar-brand p-0" to="index-2.html">
                    <img src="final.png" height="100" width="300"/>
                </Link>
                <div className="ul-cont justify-content-center">
                    <ul className="navbar-nav d-flex flex-row justify-content-start mb-2 mb-lg-0">
                

                        <Link className="nav-link" to="/">Home <span className="sr-only">(current)</span></Link>
                        <li className="nav-item">
        <Link className="nav-link" to="/about">About Us</Link>
      </li>

                         <li className="nav-item drop-cont">
                            <Link className="nav-link" to="#" role="button">
                                Services
                                <i className="fas fa-chevron-down"></i>
                            </Link>
                            <ul className="dropdown-items ps-0">
                                <li><Link className="nav-item" to="/web-application-software">Web Application Software</Link></li>
                                <li><Link className="nav-item" to="/mobile-application-development">Mobile Application</Link></li>
                                <li><Link className="nav-item" to="/website-development">Website Development </Link></li>
                             
                                <li><Link className="nav-item" to="/seo-services"> SEO Services</Link></li>
                                 <li><Link className="nav-item" to="/ecommerce-solution">E-Commerce Solution</Link></li>
                                  
                      
                            </ul>
                        </li>
                        <li className="nav-item drop-cont">
                            <Link className="nav-link" to="#" role="button">
                               Our  Product
                                <i className="fas fa-chevron-down"></i>
                            </Link>
                            <ul className="dropdown-items ps-0">
                              <li><Link className="nav-item" to="/college-automation-system">College Automation</Link></li>
                              <li><Link className="nav-item" to="/hospital-management-system">Hospital Management</Link></li>
                           
                              <li><Link className="nav-item" to="/computer-institute-software">Computer Inst. Software</Link></li>
                
                            </ul>
                        </li>


                  
                        <li className="nav-item">
        <Link className="nav-link" to="/branches">Branches</Link>
      </li>
                


                        <li className="nav-item">
        <Link className="nav-link" to="/gallery">Gallery</Link>
      </li>
                     
      <li className="nav-item mx-1">
                            <Link className="nav-link" to="contact.html">Career With Us</Link>
                        </li>
                    
                     
                        <li className="nav-item mx-1">
                            <Link className="nav-link" to="contact">Contact</Link>
                        </li>
                    
                    </ul>
                </div>
                <div className="header-quote d-none d-lg-block">
                    <Link className="unique-link" to="#">Get a Quote</Link>
                </div>
            </div>
        </nav>
        <nav className="navbar-responsive d-block d-lg-none w-100">
            <div className="container d-flex justify-content-between align-items-center position-relative">
                <Link className="navbar-brand p-0" to="index-2.html">
                <img src="real.png" height="100" width="300"/>
               </Link>
                <div className="ul-cont justify-content-center ms-auto">
                    <ul className="navbar-nav mb-2 mb-lg-0">
                    <Link className="nav-link" to="/">Home <span className="sr-only">(current)</span></Link>
                        <li className="nav-item">
        <Link className="nav-link" to="/about">About Us</Link>
      </li>

                        <li className="nav-item mx-1">
                            <Link className="nav-link" to="about.html">About Us</Link>
                        </li>
                        <li className="nav-item drop-cont">
                            <button className="nav-link">
                                Services
                                <i className="fas fa-chevron-down"></i>
                            </button>
                            <ul className="dropdown-items ps-0">
                            <li><Link className="nav-item" to="/web-application-software">Web Application Software</Link></li>
                                <li><Link className="nav-item" to="/mobile-application-development">Mobile Application</Link></li>
                                <li><Link className="nav-item" to="/website-development">Website Development </Link></li>
                             
                                <li><Link className="nav-item" to="/seo-services"> SEO Services</Link></li>
                                 <li><Link className="nav-item" to="/ecommerce-solution">E-Commerce Solution</Link></li>
                                    </ul>
                        </li>
                        <li className="nav-item drop-cont">
                            <button className="nav-link">
                                Projects
                                <i className="fas fa-chevron-down"></i>
                            </button>
                            <ul className="dropdown-items ps-0">
                                <li><Link className="nav-item" to="project-1.html">Projects1</Link></li>
                                <li><Link className="nav-item" to="project-2.html">Projects2</Link></li>
                                <li><Link className="nav-item" to="project-details.html">Project Details</Link></li>
                            </ul>
                        </li>
                        <li className="nav-item drop-cont">
                            <button className="nav-link">
                                Blog
                                <i className="fas fa-chevron-down"></i>
                            </button>
                            <ul className="dropdown-items ps-0">
                                <li><Link className="nav-item" to="blog.html">Blog</Link></li>
                                <li><Link className="nav-item" to="blog-sidebar.html">Blog Sidebar</Link></li>
                                <li><Link className="nav-item" to="blog-details.html">Blog Details</Link></li>
                            </ul>
                        </li>
                        <li className="nav-item mx-1">
                            <Link className="nav-link" to="contact.html">Contact</Link>
                        </li>
                        <li className="nav-item drop-cont">
                            <button className="nav-link">
                                Pages
                                <i className="fas fa-chevron-down"></i>
                            </button>
                            <ul className="dropdown-items ps-0">
                                <li><Link className="nav-item" to="team.html">Team</Link></li>
                                <li><Link className="nav-item" to="pricing.html">Pricing</Link></li>
                                <li><Link className="nav-item" to="faq.html">FAQ</Link></li>
                                <li><Link className="nav-item" to="testimonials.html">Testimonials</Link></li>
                                <li><Link className="nav-item" to="coming-soon.html">Coming Soon</Link></li>
                                <li><Link className="nav-item" to="404.html">404 Page</Link></li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <button className="menu-toggle-button d-block d-lg-none" type="button">
                    <span className="header-nav-icon"></span>
                    <span className="header-nav-icon"></span>
                    <span className="header-nav-icon"></span>
                </button>
            </div>
        </nav>
    </header>












    </div>
  )
}
