import React from 'react'
import { Link } from 'react-router-dom'

export const Footer = () => {
  return (
    <div>

<footer className="pt-5">
        <div className="container pb-5">
            <div className="footer-field row">
                <div className="foot-about pb-2 pb-lg-0 mb-3 mb-lg-0 col-12 col-md-6 col-lg-4">
                    <h4>
                        <i className="fas fa-angle-double-right"></i>
                        Why Us?
                    </h4>
                    <p className="pb-4">Sphinx Technology also offers ongoing support and maintenance to ensure that its customers continue to receive the highest level of performance from their software. Whether you need assistance with a technical issue, or have a question about a feature, Sphinx Technology is there to help
                    </p>

                    
                    <div className="foot-social">

                        <ul className="d-flex ps-0">
                            <li className="px-2"><a to="#"><i className="fab fa-facebook-f"></i></a></li>
                            <li className="px-2"><a to="#"><i className="fab fa-twitter"></i></a></li>
                            <li className="px-2"><a to="#"><i className="fab fa-linkedin-in"></i></a></li>
                            <li className="px-2"><a to="#"><i className="fab fa-youtube"></i></a></li>
                        </ul>
                    </div>
                </div>
                <div className="foot-contact pb-2 pb-lg-0 mb-3 mb-lg-0 col-12 col-md-6 col-lg-4">
                    <h4>
                        <i className="fas fa-angle-double-right"></i>
                        Contact Us
                    </h4>
                    <div className="footCont-link d-flex">
                        <i className="fas fa-map-marker-alt"></i>
                        <address className="mb-0">H.No 32, Maligaon, Assam, 781011</address>
                    </div>
                    <div className="footCont-link d-flex">
                        <i className="far fa-paper-plane"></i>
                        <a to="#">inform@sphinx-india.tech</a>
                    </div>
                    <div className="footCont-link d-flex">
                        <i className="fas fa-phone"></i>
                        <a to="tel:+91-7002583434">+91-7002583434</a>
                    </div>
                    <div className="subscribtion mt-3">
                        <form action="#">
                            <div className="sub-box">
                                <input type="email" placeholder="Email" required/>
                                <button type="submit">
                                    Send
                                    <i className="fas fa-location-arrow"></i>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="foot-services pb-2 pb-lg-0 mb-3 mb-lg-0 col-12 col-md-6 col-lg-2">
                    <h4>
                        <i className="fas fa-angle-double-right"></i>
                        Social Page
                    </h4>
                    <ul>
                        <li><a to="#">IT Solution</a></li>
                        <li><a to="#">Software</a></li>
                        <li><a to="#">SEO Services</a></li>
                        <li><a to="#">Digital Markiting</a></li>
                        <li><a to="#">Web Development</a></li>
                        <li><a to="#">Big Data & BI</a></li>
                    </ul>
                </div>
                <div className="foot-Links pb-2 pb-lg-0 mb-3 mb-lg-0 col-12 col-md-6 col-lg-2">
                    <h4>
                        <i className="fas fa-angle-double-right"></i>
                        Quick links
                    </h4>
                    <ul>
                        <li><a to="#">Projects</a></li>
                        <li><a to="#">Services</a></li>
                        <li><a to="#">Blog</a></li>
                        <li><a to="#">Team Members</a></li>
                        <li><a to="#">Reviews</a></li>
                        <li><a to="#">Contact</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="container">
            <div className="copy-right d-flex flex-column flex-md-row justify-content-between align-items-center py-3">
                <p className="mb-3 mb-md-0 text-center">&copy; 2013-2023 All Right Reserved </p>
                <div className="copyRight-links position-relative">
                    <a className="mx-2" to="#">Privacy Policy</a>
                    <span></span>
                    <a className="mx-2" to="#"> Terms &amp; Conditions</a>
                </div>
            </div>
        </div>
    </footer>


    </div>
  )
}
