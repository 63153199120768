
import logo from './logo.svg';
import './App.css';
import './css/style.css';
import { Navbar } from './Component/Navbar';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  withRouter
} from "react-router-dom";

import { Hospital } from './Component/Hospital';
import { Footer } from './Component/Footer';

import { Home } from './Component/Home';

import { About } from './Component/About';

import { Contact } from './Component/Contact';

import {Gallery } from './Component/Gallery';
import {Webapp } from './Component/Webapp';
import {College } from './Component/College';

import {Website } from './Component/Website';

import {Seo} from './Component/Seo';


import {Ecommerce} from './Component/Ecommerce';
import {Mobileapp } from './Component/Mobileapp';

function App() {
  return (
<>

    <Router>    
    <Navbar  title="Sphinx Technology"/>

    <Switch>

    <Route exact path="/" component={Home} />
      
    <Route exact path="/about" component={About} />
    <Route exact path="/seo-services" component={Seo} />
    <Route exact path="/ecommerce-solution" component={Ecommerce} />
    <Route exact path="/college-automation-system" component={College} />
   
    <Route exact path="/hospital-management-system" component={Hospital} />
   
   
        
    <Route exact path="/website-development" component={Website} />
    
    <Route exact path="/mobile-application-development" component={Mobileapp} />
    <Route exact path="/web-application-software" component={Webapp} />
    <Route exact path="/gallery" >

<Gallery title="Sphinx Technology"/>
      </Route>
  

          <Route exact path="/contact" component={Contact} />
            
          
        </Switch>
<Footer/>



  



    </Router>
    </>

  );
}

export default App;
