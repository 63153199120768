import React from 'react'

export const Contact = () => {
  return (
    <div>




<div className="innerhome projects-shuffle main-gradiant position-relative d-flex align-items-center justify-content-center flex-column">
        <span className="in-shape-1"></span>
        <span className="in-shape-2"></span>
        <span className="in-shape-3"></span>
        <span className="in-shape-4"></span>
        <h1>Contact Us</h1>
        <div className="inner-path">
            <a href="index-2.html" className="home">Home</a>
            <i className="fas fa-arrow-right"></i>
            <span>Contact Us</span>
        </div>

    </div>







    <section className="contact section-padding">
        <div className="container">
            <div className="main-heading">
                <h5 className="pb-2">Contact</h5>
                <h2>Keep in touch with</h2>
            </div>
            <div className="cont-info pt-5 row">
                <div className="col-12 col-md-6 col-lg-4 px-3 mb-4">
                    <div className="cont-box">
                        <h4>Location</h4>
                        <address>Honuse No: 32 
                        <br></br>
                        Maligaon, Assam, 781011</address>
                        <i className="fas fa-map-marker-alt"></i>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4 px-3 mb-4">
                    <div className="cont-box">
                        <h4>Email</h4>
                        <p>Contact us via our email and we will get back to you as soon as possible</p>
                        <a to="#">support@sphinxtechnology.in</a>
                         <i className="fas fa-paper-plane"></i>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4 px-3 mb-4 mx-auto">
                    <div className="cont-box">
                        <h4>Phone</h4>
                        <p>You can also contact us by phone and the support team will respond to you</p>
                        <a to="tel:+91-7002583434">+91-7002583434</a>
                        <a to="tel:+91-7896695062">+91-7896695062</a>
                        <i className="fas fa-phone"></i>
                    </div>
                </div>
            </div>
            <div className="contact-field">
                <div className="map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3008.9310133234326!2d92.67840351448135!3d26.81940977055811!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3744b9034eb25953%3A0x91403819629ef234!2sSphinx%20Technology!5e1!3m2!1sen!2sin!4v1674994451097!5m2!1sen!2sin"></iframe>            </div>
                <div className="contact-form-box">
                    <h3>Send Us Messages</h3>
                    <form className="form-contact" action="https://preview.availablecoder.net/Inform_template/php/contact.php" method="POST">
                        <div className="flex-form d-flex justify-content-between flex-wrap">
                            <div className="name">
                                <input className="w-100" type="text" name="name" required placeholder="Name"/>
                            </div>
                            <div className="email">
                                <input className="w-100" type="email" name="email" pattern="[^@\s]+@[^@\s]+\.[^@\s]+" required placeholder="Email"/>
                            </div>
                            <div className="name">
                                <input className="w-100" type="text" name="subject" required placeholder="Subject"/>
                            </div>
                            <div className="message">
                                <textarea className="w-100" name="message" required placeholder="Message"></textarea>
                            </div>
                        </div>
                        <button className="main-btn-2" type="submit">
                            Send Message
                            <i className="far fa-paper-plane"></i>
                        </button>
                        <p className="respond-message"></p>
                    </form>
                </div>
            </div>
        </div>
    </section>



    </div>
  )
}
