import React from 'react'

export const Home = () => {
  return (
    <div>

  
<section className="home1 main-gradiant" id="home1">
        <img className="frame" src="images/frame.svg" alt="background-frame"/>
        <div className="container">
            <div className="home1-field d-flex flex-column flex-lg-row align-items-center justify-content-between">
                <div className="text">
                    <h1>We Provide many IT &amp; Technology Services</h1>
                    <p>"Revolutionizing the Future of Technology: Sphinx Technology Leads the Way"



</p>
                    <div className="home-links">
                        <a className="main-btn-3 mx-2" to="services-1.html">Our Services
                            <i className="fas fa-angle-double-right"></i>
                        </a>
                        <a className="main-btn-4 mx-2" to="contact.html">Contact Us
                            <i className="fas fa-angle-double-right"></i>
                        </a>
                    </div>
                </div>
                <div className="image ms-3 ms-lg-0">
                    <img className="img-fluid w-100 mx-auto" src="images/home_slider/home-default.svg" alt="home image"/>
                </div>
            </div>

        </div>
    </section>
    <section className="about section-padding" id="about">
        <div className="container">
            <div className="about-field justify-content-between row">
                <div className="about-content col-12 col-lg-6">
                    <div className="text">
                        <h5 className="pb-2">About Sphinx Technology</h5>
                        <h2>We are Experienced in Business and Technology</h2>
                    </div>
                    <p className="pb-2">

                    "Empowering Progress Through Innovative Solutions: Sphinx Technology's Mission"
                    </p>
                    <div className="about-prog">
                        <div className="pb-3 about-bar">
                            <div className="pb-2 prog-text d-flex justify-content-between">
                                <h4>IT Support</h4>
                                <span className="value">85%</span>
                            </div>
                            <div className="ab-load">
                                <span className="liq-load" data-progress="85%"></span>
                            </div>
                        </div>
                        <div className="pb-3 about-bar">
                            <div className="pb-2 prog-text d-flex justify-content-between">
                                <h4>IT Consulting</h4>
                                <span className="value">90%</span>
                            </div>
                            <div className="ab-load">
                                <span className="liq-load" data-progress="90%"></span>
                            </div>
                        </div>
                        <div className="pb-5 about-bar">
                            <div className="pb-2 prog-text d-flex justify-content-between">
                                <h4>Managed IT Services</h4>
                                <span className="value">75%</span>
                            </div>
                            <div className="ab-load">
                                <span className="liq-load" data-progress="75%"></span>
                            </div>
                        </div>
                        <a to="about.html" className="main-btn-1">Learn More
                            <i className="fas fa-angle-double-right"></i>
                        </a>
                    </div>
                </div>
                <div className="about-image mx-auto mx-lg-0 mt-5 mt-lg-0 col-12 col-lg-6">
                    <div className="image position-relative ms-0 ms-lg-5 me-0 me-lg-5">
                        <div className="ab-img-border"></div>
                        <div className="img-box">
                            <img className="img-fluid w-100 d-block" src="images/about.jpg" alt="about image"/>
                        </div>
                        <div className="years d-flex flex-column">
                            <span className="number">10+</span>
                            <em className="mb-0">years of experience</em>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    





    <section className="moreview bg-color-gray section-padding" id="moreview">
        <div className="container">
            <div className="over-field align-items-center row">
                <div className="image col-12 col-lg-6 pe-2 pe-lg-4 mb-4 mb-lg-0">
                    <img className="img-fluid w-100" src="images/view.svg" alt="about image"/>
                </div>
                <div className="view-content col-lg-6">
                    <div className="text">
                        <h5 className="pb-2">Why trust us</h5>
                        <h2>The security of your personal information and data is our responsibility</h2>
                    </div>
                    <p className="pb-2">"Protecting Your Digital World: The Importance of IT Security"</p>
                    <div className="merits pt-2">
                        <span>Cloud & Storage</span>
                        <span>Cloud Security</span>
                        <span>Cybersecurity threats</span>
                        <span>Blockchain</span>
                        <span>Network Management</span>
                        <span>Consulting Services</span>
                    </div>
                </div>
              
            </div>
        </div>
    </section>
    <section className="services section-padding">
        <div className="container">
            <div className="main-heading">
                <h5 className="pb-2">Services of Sphinx Technology</h5>
                <h2>The Services we Provide</h2>
            </div>
            <div className="serv-field mt-5 row">
                <div className="serv-box mb-4 col-12 col-md-6 col-lg-4">
                    <div className="serv-cont serv-cont-2 p-4">
                        <div className="image p-3 rounded-circle">
                            <img src="images/services/services1.png" alt="services image"/>
                        </div>
                        <h3 className="my-3">Software Development</h3>
                     
                    </div>
                </div>
                <div className="serv-box mb-4 col-12 col-md-6 col-lg-4">
                    <div className="serv-cont serv-cont-2 p-4">
                        <div className="image p-3 rounded-circle">
                            <img src="images/services/services2.png" alt="services image"/>
                        </div>
                        <h3 className="my-3">Web Development</h3>
                      
                    </div>
                </div>
                <div className="serv-box mb-4 col-12 col-md-6 col-lg-4">
                    <div className="serv-cont serv-cont-2 p-4">
                        <div className="image p-3 rounded-circle">
                            <img src="images/services/services3.png" alt="services image"/>
                        </div>
                        <h3 className="my-3">SEO Services</h3>
                  
                    </div>
                </div>
                <div className="serv-box mb-4 col-12 col-md-6 col-lg-4">
                    <div className="serv-cont serv-cont-2 p-4">
                        <div className="image p-3 rounded-circle">
                            <img src="images/services/services4.png" alt="services image"/>
                        </div>
                        <h3 className="my-3">Artificial Intelligence</h3>
                    </div>
                </div>
                <div className="serv-box mb-4 col-12 col-md-6 col-lg-4">
                    <div className="serv-cont serv-cont-2 p-4">
                        <div className="image p-3 rounded-circle">
                            <img src="images/services/services5.png" alt="services image"/>
                        </div>
                        <h3 className="my-3">Digital Marketing</h3>
                    </div>
                </div>
                <div className="serv-box mb-4 col-12 col-md-6 col-lg-4">
                    <div className="serv-cont serv-cont-2 p-4">
                        <div className="image p-3 rounded-circle">
                            <img src="images/services/services6.png" alt="services image"/>
                        </div>
                        <h3 className="my-3">Big Data &amp; BI</h3>
                    </div>
                </div>
            </div>

        </div>
    </section>
    <section className="projects bg-color-gray section-padding">
        <div className="container">
            <div className="main-heading">
             </div>
            <div className="projects-field projects-shuffle pt-5 row justify-content-center">
                <div className="project2-f-col col-12 col-md-6 row">
                
                </div>
            </div>
     
        </div>
    </section>
    <section className="counter-1 main-gradiant pt-5 pb-5" id="counter">
        <div className="container">
            <div className="d-flex justify-content-between flex-wrap counter-area">
                <div className="position-relative text-center">
                    <div className="icon">
                        <i className="far fa-thumbs-up"></i>
                    </div>
                    <span className="odometer" data-count="362">00</span>
                    <h4>Successful Projects</h4>
                </div>       
                <div className="position-relative text-center">
                    <div className="icon">
                        <i className="far fa-user"></i>
                    </div>
                    <span className="odometer" data-count="648">00</span>
                    <h4>Employees</h4>
                </div>
                <div className="position-relative text-center">
                    <div className="icon">
                        <i className="far fa-smile"></i>
                    </div>
                    <span className="odometer" data-count="245">00</span>
                    <h4>Happy Clients</h4>
                </div>
                <div className="position-relative text-center">
                    <div className="icon">
                        <i className="fas fa-award"></i>
                    </div>
                    <span className="odometer" data-count="68">00</span>
                    <h4>5 Star Rating</h4>
                </div>
            </div>
        </div>
    </section>
    <section className="review bg-color-gray section-padding">
        <div className="container">
            <div className="main-heading">
                <h5 className="pb-2">Reviews</h5>
                <h2>Our Clients Feedback</h2>
            </div>
            <div className="review-slider">
                <div className="swiper-wrapper">
                    <div className="swiper-slide">
                        <div className="image pt-2 mb-4 text-center position-relative">
                            <img className="rounded-circle" src="images/clients/client1.jpg" alt="review image"/>
                            <span></span>
                        </div>
                        <div className="client-info">
                            <q className="mb-3 text-center">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et</q>
                            <h4 className="text-center">Blake Carter</h4>
                            <span className="d-block text-center">VIP Client</span>
                        </div>
                    </div>
                    <div className="swiper-slide">
                        <div className="image pt-2 mb-4 text-center position-relative">
                            <img className="rounded-circle" src="images/clients/client2.jpg" alt="review image"/>
                            <span></span>
                        </div>
                        <div className="client-info">
                            <q className="mb-3 text-center">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et</q>
                            <h4 className="text-center">Bella Talon</h4>
                            <span className="d-block text-center">VIP Client</span>
                        </div>
                    </div>
                    <div className="swiper-slide">
                        <div className="image pt-2 mb-4 text-center position-relative">
                            <img className="rounded-circle" src="images/clients/client3.jpg" alt="review image"/>
                            <span></span>
                        </div>
                        <div className="client-info">
                            <q className="mb-3 text-center">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et</q>
                            <h4 className="text-center">Robert Waylon</h4>
                            <span className="d-block text-center">VIP Client</span>
                        </div>
                    </div>
                    <div className="swiper-slide">
                        <div className="image pt-2 mb-4 text-center position-relative">
                            <img className="rounded-circle" src="images/clients/client4.jpg" alt="review image"/>
                            <span></span>
                        </div>
                        <div className="client-info">
                            <q className="mb-3 text-center">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et</q>
                            <h4 className="text-center">Elise Maxwell</h4>
                            <span className="d-block text-center">VIP Client</span>
                        </div>
                    </div>
                </div>
                <div className="swiper-pagination"></div>
              </div>
        </div>
    </section>
    <section className="new overlay py-5" id="new">
        <div className="container text-center">
            <div className="new-cont mx-auto">
                <h5>Let's work togethar?</h5>
                <h3 className="my-3">Grow Your Business And Achieve Your Ambitions.</h3>
                <p className="mb-5">Sphinx Technology is the perfect partner for growing businesses looking to thrive in today's digital age. With our innovative solutions and expert support, you can focus on growing your business and leave the technology to us. Contact us today to learn more about how we can help your business succeed.</p>
                <div className="new-links">
                    <a className="main-btn-2" to="tel:#">
                        Call us
                        <i className="fas fa-phone-alt"></i>
                    </a>
                
                </div>
            </div>
        </div>
    </section>
    <section className="contact section-padding">
        <div className="container">
            <div className="main-heading">
                <h5 className="pb-2">Contact</h5>
                <h2>Keep in touch with</h2>
            </div>
            <div className="cont-info pt-5 row">
                <div className="col-12 col-md-6 col-lg-4 px-3 mb-4">
                    <div className="cont-box">
                        <h4>Reach Us</h4>
                        <address></address>
                        <address className="mb-0">Honuse No: 32 
                        <br></br>
                        Maligaon, Assam, 781011</address>
                        <i className="fas fa-map-marker-alt"></i>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4 px-3 mb-4">
                    <div className="cont-box">
                        <h4>Email</h4>
                        <p>Contact us via our email and we will get back to you as soon as possible</p>
                        <a to="#">support@sphinxtechnology.in</a>
                         <i className="fas fa-paper-plane"></i>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4 px-3 mb-4 mx-auto">
                    <div className="cont-box">
                        <h4>Phone</h4>
                        <p>You can also contact us by phone and the support team will respond to you</p>
                        <a to="tel:+91-7002583434">+91-7002583434</a>
                        <a to="tel:+91-7896695062">+91-7896695062</a>
                        <i className="fas fa-phone"></i>
                    </div>
                </div>
            </div>
            <div className="contact-send-field mt-4 p-2 text-center">
                <a to="contact.html" className="main-btn-1">
                    Send Message
                    <i className="fas fa-angle-double-right"></i>
                </a>
            </div>
        </div>
    </section>
    <div className="partners bg-color-gray py-3">
        <div className="container">
            <div className="partner-slider">
                <div className="swiper-wrapper">
                    <div className="swiper-slide p-3">
                        <a className="d-block w-100" to="#">
                            <img className="img-fluid w-100" src="images/partners/part1.png" alt="partners"/>
                        </a>
                    </div>
                    <div className="swiper-slide p-3">
                        <a className="d-block w-100" to="#">
                            <img className="img-fluid w-100" src="images/partners/part2.png" alt="partners"/>
                        </a>
                    </div>
                    <div className="swiper-slide p-3">
                        <a className="d-block w-100" to="#">
                            <img className="img-fluid w-100" src="images/partners/part3.png" alt="partners"/>
                        </a>
                    </div>
                    <div className="swiper-slide p-3">
                        <a className="d-block w-100" to="#">
                            <img className="img-fluid w-100" src="images/partners/part4.png" alt="partners"/>
                        </a>
                    </div>
                    <div className="swiper-slide p-3">
                        <a className="d-block w-100" to="#">
                            <img className="img-fluid w-100" src="images/partners/part5.png" alt="partners"/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>



    </div>
  )
}
