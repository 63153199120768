import React from 'react'

export const About = () => {
  
  return (
    
    <div>

<div className="innerhome projects-shuffle main-gradiant position-relative d-flex align-items-center justify-content-center flex-column">
        <span className="in-shape-1"></span>
        <span className="in-shape-2"></span>
        <span className="in-shape-3"></span>
        <span className="in-shape-4"></span>
        <h1>About Us</h1>
        <div className="inner-path">
            <a href="index-2.html" className="home">Home</a>
            <i className="fas fa-arrow-right"></i>
            <span>About</span>
        </div>

    </div>
    <section className="about section-padding" id="about">
        <div className="container">
            <div className="about-field justify-content-between row">
                <div className="about-content col-12 col-lg-6">
                    <div className="text">
                        <h5 className="pb-2">About Company</h5>
                        <h2>We are Experienced in Business and Technology</h2>
                    </div>
                    <p className="pb-2">Sphinx Technology is a web application software development company that specializes in creating custom web applications for businesses of all sizes. Our team of experienced developers and designers are dedicated to creating high-quality, user-friendly web applications that are tailored to meet the unique needs of each client.</p>
                    <div className="about-prog">
                        <div className="pb-3 about-bar">
                            <div className="pb-2 prog-text d-flex justify-content-between">
                                <h4>IT Support</h4>
                                <span className="value">85%</span>
                            </div>
                            <div className="ab-load">
                                <span className="liq-load" data-progress="85%"></span>
                            </div>
                        </div>
                        <div className="pb-3 about-bar">
                            <div className="pb-2 prog-text d-flex justify-content-between">
                                <h4>IT Consulting</h4>
                                <span className="value">90%</span>
                            </div>
                            <div className="ab-load">
                                <span className="liq-load" data-progress="90%"></span>
                            </div>
                        </div>
                        <div className="pb-5 about-bar">
                            <div className="pb-2 prog-text d-flex justify-content-between">
                                <h4>Managed IT Services</h4>
                                <span className="value">75%</span>
                            </div>
                            <div className="ab-load">
                                <span className="liq-load" data-progress="75%"></span>
                            </div>
                        </div>
                   
                    </div>
                </div>
                <div className="about-image mx-auto mx-lg-0 mt-5 mt-lg-0 col-12 col-lg-6">
                    <div className="image position-relative ms-0 ms-lg-5 me-0 me-lg-5">
                        <div className="ab-img-border"></div>
                        <div className="img-box">
                            <img className="img-fluid w-100 d-block" src="images/about.jpg" alt="about image"/>
                        </div>
                        <div className="years d-flex flex-column">
                            <span className="number">10+</span>
                            <em className="mb-0">years of experience</em>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    </div>
  )
}
